@charset "utf-8";

@import "minimal-mistakes/skins/air"; // skin
@import "minimal-mistakes"; // main partials

.author__large {
  width: 340px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.author__avatar__large {
  display: table-cell;
  vertical-align: top;
  height: 175px;

  img {
     max-width: 340px;
     border-radius: 50%;

     @include breakpoint($large) {
         padding: 5px;
         border: 1px solid $border-color;
     }
  }
}

.author__content__large {
  display: inline;
  line-height: 1;

  @include breakpoint($large) {
    display: block;
    width: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.author__name__large {
  text-align: center
}